import styles from "./expertiseCard.module.scss";

const ExpertiseCard = ({ title, imgSrc, description, imgStyle = {} }) => {
  return (
    <div className={styles.expertiseCard}>
      <div className={styles.content}>
        <div className={styles.image}>
          <img src={imgSrc} alt="titleImg" style={{ ...imgStyle }} />
        </div>
        <h2 className={styles.title}>{title}</h2>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.triangle}></div>
    </div>
  );
};

export default ExpertiseCard;
