import { PopupModal } from "react-calendly";

const CalendlyPopupModal = ({ open, onClose, url }) => {
  return (
    <PopupModal
      url={url}
      onModalClose={onClose}
      open={open}
      rootElement={document.getElementById("root")}
    />
  );
};

export default CalendlyPopupModal;
