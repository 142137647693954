import { useMemo, useState } from "react";
import { Row, Col, Container, Button, Spinner, Alert } from "react-bootstrap";
import { UserForm } from "./UserForm";
import styles from "./styles.module.scss";
import { ValidateEmail } from "../../../Utils/validateEmail";
import { validateLinkedInProfileLink } from "../../../Utils/validateLinkedInLink";
import { ReactComponent as TriangleContainer } from "../../../assets/svg/triangleLogo.svg";
import { useMedia } from "react-use";
import { ReactComponent as SignupFormLogo } from "../../../assets/svg/signupFormLogo.svg";
import axios from "axios";
import { Urls } from "../../../constants";
import { TimeZoneList } from "./timeZone";
import { useLocation } from "react-router-dom";

export const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [timeZone, setTimeZone] = useState(TimeZoneList[8]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isMobile = useMedia("(max-width: 768px)", false);
  const [isLoading, setLoading] = useState(false);
  const [signUpRes, setSignUpRes] = useState(null);
  // errors
  const [errors1, setErrors1] = useState(null);
  const totalSteps = 2;

  const params = useLocation();
  const paramsArr = params.pathname.split("/");
  const routeName = paramsArr[paramsArr.length - 1];

  const onPrevStep = () => {
    if (currentStep !== 1 && currentStep <= totalSteps) {
      setCurrentStep((s) => s - 1);
    }
  };
  const passValidityTest = (password) => {
    if (
      password.length >= 12 &&
      specialCharTest(password) &&
      upperTest(password) &&
      lowerTest(password) &&
      numTest(password)
    ) {
      return true;
    } else {
      return false;
    }
  };
  function specialCharTest(password) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(password)) {
      return specialChars.test(password);
    } else {
      return false;
    }
  }
  function numTest(password) {
    if (/\d/.test(password)) {
      return /\d/.test(password);
    }
    return /\d/.test(password);
  }
  function upperTest(password) {
    for (let char in password) {
      if (
        password[char] === password[char].toUpperCase() &&
        /^[a-zA-Z]+$/.test(password[char])
      ) {
        return true;
      }
    }
    return false;
  }
  function lowerTest(password) {
    for (let char in password) {
      if (
        password[char] === password[char].toLowerCase() &&
        /^[a-zA-Z]+$/.test(password[char])
      ) {
        return true;
      }
    }
    return false;
  }
  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setTimeZone("America");
    setLinkedInProfile("");
  };
  const getRouteName = useMemo(() => {
    if (routeName === "containers") {
      return "Demystifying Cyber Risks: Containers";
    } else if (routeName === "signup") {
      return "signup";
    } else {
      return `Demystifying Cyber Risks: ${routeName}`;
    }
  }, [routeName]);
  const postUserData = async (
    email,
    password,
    firstName,
    landing_page,
    lastName,
    zoneInfo,
    linkedin_url,
    linkedin_username
  ) => {
    console.log({
      email: email,
      userName: `${firstName} ${lastName}`,
      password: password,
    });
    setLoading(true);
    try {
      const data = await axios({
        method: "POST",
        url: "https://loqrx56wx0.execute-api.us-east-1.amazonaws.com/dev/signup",
        data: JSON.stringify({
          email,
          name: `${firstName} ${lastName}`,
          password,
          // zoneInfo,
          landing_page,
          // linkedin_url,
          linkedin_username,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(data);
      setLoading(false);
      if (
        data?.data?.outcome?.statusCode === 200 &&
        data?.data?.outcome?.status === "SUCCESS"
      ) {
        setSignUpRes({
          ...data?.data?.outcome,
          message: "User created successfully in Train GRC Academy & Labs",
        });
        // console.log(data);
        // data?.data?.outcome?.message = ""
      } else {
        setSignUpRes(data?.data?.outcome);
      }
      if (data?.data?.outcome?.statusCode === 200) {
        resetForm();
        window.location.href = Urls.academyLogin;
      }
    } catch (error) {
      console.log("error");
      console.log(error);
      setLoading(false);
    }
  };

  const FormValidation1 = () => {
    const newError = {};
    if (!firstName) {
      // newError.firstName = "Please provide your first name";
      newError.firstName = "* Required";
    }
    if (!lastName) {
      // newError.lastName = "Please provide your second name";
      newError.lastName = "* Required";
    }
    if (!email) {
      // newError.email = "Please provide your email";
      newError.email = "* Required";
    } else if (!ValidateEmail(email)) {
      newError.email = "Please provide the valid email";
    }
    // console.log(timeZone, 'timeZone')
    // if (!timeZone) {
    //   // newError.timeZone = "Please select the time zone";
    //   newError.timeZone = "* Required";
    // }
    if (!password) {
      // newError.password = "Please provide the password";
      newError.password = "* Required";
    } else if (!passValidityTest(password)) {
      newError.password = (
        <div>
          Password must match the following criteria: <br /> 1) Minimum Length:
          12 Characters <br /> 2) Contains a number <br /> 3) Contains a special
          character <br /> 4) Contains an uppercase letter <br /> 5) Contains an
          lowercase letter
        </div>
      );
    }
    if (!confirmPassword) {
      newError.confirmPassword = "* Required";
    }
    if (confirmPassword !== password) {
      newError.confirmPassword = "Password must be same";
    }
    // if (validateLinkedInProfileLink(linkedInProfile) && linkedInProfile) {
    //   // newError.linkedInProfile = "Please enter valid LinkedIn Profile URL";
    //   if (linkedInProfile.startsWith("linkedin.com/in")) {
    //     console.log(`https://${linkedInProfile}`);
    //   } else {
    //     console.log(linkedInProfile);
    //   }
    // } else {
    //   if (linkedInProfile.startsWith("/")) {
    //     console.log(`https://linkedin.com/in${linkedInProfile}`);
    //   } else {
    //     console.log(`https://linkedin.com/in/${linkedInProfile}`);
    //   }
    // }

    return Object.keys(newError)?.length === 0 ? null : newError;
  };

  const onNextStep = () => {
    setSignUpRes(null);
    const formValidation1 = FormValidation1();
    setErrors1(formValidation1);
    if (formValidation1 === null) {
      postUserData(
        email,
        password,
        firstName,
        getRouteName,
        lastName,
        timeZone,
        linkedInProfile
          ? `https://www.linkedin.com/in/${linkedInProfile}`
          : null,
        email,
        password,
        firstName,
        `Demystifying Cyber Risks: ${getRouteName}`,
        lastName,
        timeZone,
        linkedInProfile
          ? `https://www.linkedin.com/in/${linkedInProfile}`
          : null,
        linkedInProfile
      );
    }
  };

  const RightSide = () => {
    return (
      <div className={styles.rightSide}>
        <div className={styles.header}>
          {`Already have an account? `}
          <a href="https://academy.traingrc.com/login">Sign In</a>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.form}>
            <div className={styles.title}>Sign up for Train GRC Academy</div>
            <UserForm
              errors={errors1}
              firstName={firstName}
              lastName={lastName}
              email={email}
              timeZone={timeZone}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setTimeZone={setTimeZone}
              linkedInProfile={linkedInProfile}
              setLinkedInProfile={setLinkedInProfile}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
            />
            {signUpRes && (
              <Alert
                variant={signUpRes?.statusCode === 200 ? "success" : "danger"}
              >
                {signUpRes?.message}
              </Alert>
            )}
            <div className={styles.next}>
              <Button
                variant="warning"
                size="lg"
                block
                onClick={onNextStep}
                className="nextBtn"
                disabled={isLoading}
              >
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {isLoading ? "Loading..." : `Sign Up`}
              </Button>
            </div>
            <div className={styles.signupLogo}>
              <SignupFormLogo />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.signup}>
      {isMobile ? (
        RightSide()
      ) : (
        <Row className={styles.row}>
          <Col sm={4}>
            <div className={styles.left}>
              <div className={styles.leftSide}>
                <Container fluid>
                  <div className={styles.content}>
                    <img
                      src="https://teachable-logo.s3.amazonaws.com/thankyou_footer_icon.png"
                      className={styles.logo}
                      alt="Auth_Logo"
                    />
                    <div className={styles.title}>Train GRC Academy</div>

                    <hr />
                    <div className={styles.guide}>
                      <div className={styles.text1}>Start Your Journey To</div>
                      <div className={styles.text2}>
                        Become A Cyber Risk Expert.
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
              <div className={styles.leftTriangle}>
                <TriangleContainer />
              </div>
            </div>
          </Col>
          <Col sm={isMobile ? 12 : 8}>{RightSide()}</Col>
        </Row>
      )}
    </div>
  );
};
