import { Row, Col } from "react-bootstrap";
import FirstImage from "../../assets/aboutUs/firstImage.png";
import SecondImage from "../../assets/aboutUs/secondImage.png";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useMedia } from "react-use";

const FirstBlock = () => {
  const isMobile = useMedia("(max-width: 768px)", false);

  return (
    <div className={styles.firstBlock}>
      <Row className="d-flex justify-content-center">
        <Col
          xl={6}
          lg={6}
          sm={12}
          className={classNames([
            {
              "mb-3": isMobile,
            },
          ])}
        >
          <div className={classNames([styles.card, styles.item])}>
            <h2 className={styles.title}>
              Hands-On Skills
              <br />
              <span className="text-warning">For Real World Problems.</span>
            </h2>
            <div className={styles.description}>
              While the cloud enables innovation and technological capacities
              companies couldn’t have dreamed of a decade ago, those
              technologies are not magic boxes that run without configuration or
              maintenance.
              <br />
              <br />
              The process of building and managing cloud technologies must be
              governed, regardless of the promises offered by automated
              solutions. To provide meaningful assurance, organizations must
              assess staffing, processes, and technologies to ensure
              operational, financial, and security objectives are met. Train GRC
              can help break through the complexity to provide a clear
              understanding of security assessment tools and techniques that can
              be used to inform these efforts.
            </div>
          </div>
        </Col>
        <Col xl={6} lg={6} sm={12}>
          <div className={styles.image} classNames={styles.item}>
            <img
              src={FirstImage}
              alt="firstImage"
              className={styles.firstImage}
            />
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col
          xl={6}
          lg={6}
          sm={12}
          className={classNames([
            {
              "mb-3": isMobile,
            },
          ])}
        >
          <div className={styles.image}>
            <img
              src={SecondImage}
              alt="secondImage"
              className={styles.secondImage}
            />
          </div>
        </Col>
        <Col xl={6} lg={6} sm={12}>
          <div className={styles.card}>
            <h2 className={styles.title}>
              We Teach Cloud Auditing
              <br />
              <span className="text-warning">From First Principles.</span>
            </h2>
            <div className={styles.description}>
              Even companies that use identical technologies may be using the
              technologies in very divergent ways. In fact, it’s the integration
              of various technologies into unified capabilities that allows
              organizations to achieve powerful business outcomes while
              simultaneously increasing overall attack surfaces. Due to typical
              technology vendor sprawl, the notion that tools are coming to
              “ensure” or “prove” security in a comprehensive manner seems
              unlikely.
              <br />
              <br />
              For these reasons, we teach cloud auditing from first principles,
              starting with an understanding of the capabilities and threats
              that are relevant to cloud environments. Using these principles as
              the foundation for our assessments, we help our GRC professionals
              gain hands-on, technical skills with the services used by
              engineering teams, providing you the skills to meet on-going or
              newly identified security and compliance obligations.
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FirstBlock;
