import HomeSecondImage from "../../assets/home/secondBlock.png";
import { Container } from "react-bootstrap";
import { Button as AmplifyButton } from "@aws-amplify/ui-react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import YouTubePlayer from "../../components/youtubeVideo";
import BackgroundImage from "../../assets/home/Background.png"

const SecondBlock = () => {
  const history = useHistory();
  return (
    <div className={styles.secondBlock}>
      <div className={styles.left}>
        <img
          src={HomeSecondImage}
          alt="HomeSecondImage"
          className={styles.leftImg}
        />
        <div className={styles.btn}>
          <AmplifyButton
            display="flex"
            gap="0"
            direction="row"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="5px"
            border="1px solid #2B5AA1"
            padding="8px 16px 8px 16px"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Sign Up Now"
            outline="none"
            backgroundColor="#2B5AA1"
            color="#ffff"
            alignSelf="center"
            onClick={() => history.push("/signup")}
            className="signupBtn"
          />
        </div>
      </div>
      <div className={styles.right}>
        <YouTubePlayer
          video="9EewBGfI-kM"
          height="100%"
          autoplay={1}
          styleContainer={{
            background: `linear-gradient(0deg, rgba(242, 239, 238, 0.8), rgba(242, 239, 238, 0.8)), url(${BackgroundImage})`,
            backgroundRepeat: "round"
          }}
        />
      </div>
    </div>
  );
};

export default SecondBlock;
