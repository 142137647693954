import NavBar from "../NavBar";
// import { Navigation } from "../../ui-components";
import Footer from "./footer";

const Layout = ({ children, active, color }) => {
  return (
    <>
      <NavBar active={active} />
      {/* <Navigation /> */}
      {children}
      <Footer color={color} />
    </>
  );
};

export default Layout;
