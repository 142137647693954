import React from "react";
import SecondBlock from "../../components/Home/secondBlock";
import HelpBlock from "../../components/Home/helpBlock";
import MouseImage from "../../assets/home/mouse.png";
import CardBlock from "../../components/Home/cardBlock";
import Layout from "../../components/Home/layout";
import styles from "./styles.module.scss";
import LaptopImg from "../../assets/home/laptop.png";
import ClientChat from "../../assets/home/chat.png";
import { useMedia } from "react-use";
import { useTitle } from "../../hooks";

const Home = () => {
  const isMobile = useMedia("(max-width: 768px)", false);
  useTitle("Train GRC - Seize Your Expertise");
  return (
    <Layout>
      <div className={styles.home}>
        <SecondBlock />
        {/* <div className={styles.homeHeader}>
          <Header
            overrides={{
              Header: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
              HeroHeader: {},
              "Frame 561": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              },
              WatchVideo: {
                border: "1px solid red",
              },
            }}
            direction="row"
            justifyContent="space-between"
          />
        </div> */}
        <div className={styles.laptopBlock}>
          {/* <div className={styles.inner}>
            <LaptopIcon />
          </div> */}
          <img src={LaptopImg} alt="LaptopImg" className={styles.mouseImg} />
        </div>
        <HelpBlock />
        <div className={styles.mouseImgBlock}>
          <img src={MouseImage} alt="MouseImage" className={styles.mouseImg} />
          {/* {!isMobile && (
            <div className={styles.chat}>
              <img
                src={ClientChat}
                alt="ClientChat"
                className={styles.clientChat}
              />
              <h2 className={styles.clientTitle}>What Our Student's Say:</h2>
            </div>
          )} */}
        </div>
        {/* {isMobile && (
          <div className={styles.chat}>
            <img
              src={ClientChat}
              alt="ClientChat"
              className={styles.clientChat}
            />
            <h2 className={styles.clientTitle}>What Our Student's Say:</h2>
          </div>
        )} */}
        {/* <div className={styles.cardBlockContainer}>
          <CardBlock />
        </div> */}
      </div>
    </Layout>
  );
};

export default Home;
