import styles from "./personCard.module.scss";

const PersonCard = ({ description, name, post, titleImg }) => {
  return (
    <div className={styles.personCard}>
      <div className={styles.titleImg}>
        <img src={titleImg} alt="titleImg" />
      </div>
      <div className={styles.content}>
        <div className={styles.description}>
          {description?.map((item, index) => (
            <p key={index}> {item}</p>
          ))}
        </div>
        <div className={styles.author}>
          <div className={styles.name}>{name}</div>
          <div className={styles.post}>{post}</div>
        </div>
      </div>
    </div>
  );
};
export default PersonCard;
