/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function Frame549(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="20px"
      direction="column"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Frame549")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        border="4px SOLID rgba(255,204,4,1)"
        borderRadius="80px"
        padding="36px 36px 36px 36px"
        {...getOverrideProps(overrides, "Frame 544")}
      >
        <Icon
          width="66.93px"
          height="67px"
          viewBox={{ minX: 0, minY: 0, width: 66.9296875, height: 67 }}
          paths={[
            {
              d: "M35.1398 37.4067L36.56 39.4411C37.3208 40.5091 38.8931 40.5091 39.6539 39.4411L42.1898 35.7792C43.9143 33.338 46.0952 31.2528 48.5805 29.6762L64.3543 13.859C67.4989 10.7058 67.4989 5.51819 64.3543 2.36494C61.2097 -0.788312 56.0363 -0.788312 52.8917 2.36494L37.1179 18.1821L36.8643 18.5889C35.4441 20.8267 33.669 22.8102 31.5388 24.4377L27.5826 27.4892C26.5682 28.2521 26.619 29.8287 27.6841 30.5408L29.3578 31.7105C30.2201 32.3208 30.3722 33.5414 29.6114 34.3043L14.6492 49.4094C13.4319 50.63 11.9103 51.4438 10.1858 51.698L5.82396 52.4609L0.802734 63.243L3.69375 66.142L14.4463 61.1069L15.2071 56.7331C15.5114 55.0547 16.3229 53.4781 17.4894 52.2575L32.5531 37.1524C33.3139 36.4404 34.5312 36.5421 35.1398 37.4067ZM14.6483 26.5229C16.0684 26.3703 17.4886 26.9297 18.503 27.9469L25.8573 35.3214L27.9875 33.1854L26.6181 32.2699C25.6037 31.5579 24.9443 30.3881 24.8936 29.1167C24.8429 27.8452 25.4515 26.6754 26.4152 25.9126L30.3713 22.861L31.2843 22.0981L27.7846 18.5889C26.7703 17.5717 26.2123 16.1477 26.3645 14.7236C26.7702 10.9092 25.5023 6.94219 22.5605 3.99238C19.7202 1.14428 15.9163 -0.12719 12.2138 0.177964L13.6846 2.72091L17.0321 8.4171L13.9382 13.1978L8.25764 13.6047L4.91015 7.90851L3.13497 4.85698C-1.27763 10.0446 -1.02403 17.826 3.84504 22.7593C6.88821 25.6583 10.8443 26.9297 14.6483 26.5229ZM48.3761 39.2376C49.3905 40.2548 50.8106 40.8142 52.2308 40.6616C56.0347 40.2548 59.9909 41.5262 63.0848 44.3743C67.9538 49.3077 68.2074 57.0891 63.7948 62.2767L62.0196 59.2251L58.6722 53.5289L52.9916 53.9358L49.8977 58.7165L53.2452 64.4127L54.716 66.9557C51.0135 67.2608 47.2095 65.9894 44.3693 63.1413C41.4275 60.1914 40.1595 56.2245 40.5653 52.41C40.7174 50.986 40.1595 49.5619 39.1451 48.5448L31.6387 41.0177L33.7689 38.8816L34.9861 40.6108C35.6962 41.6788 36.9135 42.2891 38.1815 42.2891C39.4495 42.2891 40.6667 41.6788 41.3768 40.6108L43.9128 36.9489C44.0649 36.7455 44.2298 36.5421 44.3946 36.3386C44.5595 36.1352 44.7243 35.9318 44.8764 35.7283L48.3761 39.2376Z",
              fill: "rgba(255,204,4,1)",
              fillRule: "evenodd",
            },
          ]}
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Union")}
        >
          <Icon
            width="65.91px"
            height="66.14px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 65.91015625,
              height: 66.1419677734375,
            }}
            paths={[
              {
                d: "M34.3371 37.4067L35.7572 39.4411C36.518 40.5091 38.0903 40.5091 38.8511 39.4411L41.3871 35.7792C43.1116 33.338 45.2925 31.2528 47.7778 29.6762L63.5515 13.859C66.6961 10.7058 66.6961 5.51819 63.5515 2.36494C60.4069 -0.788313 55.2335 -0.788313 52.0889 2.36494L36.3152 18.1821L36.0616 18.5889C34.6414 20.8267 32.8662 22.8102 30.736 24.4377L26.7799 27.4892C25.7655 28.2521 25.8162 29.8287 26.8813 30.5408L28.5551 31.7105C29.4173 32.3208 29.5695 33.5414 28.8087 34.3043L13.8464 49.4094C12.6292 50.63 11.1076 51.4438 9.38311 51.698L5.02123 52.4609L0 63.243L2.89102 66.142L13.6435 61.1069L14.4043 56.7331C14.7086 55.0547 15.5202 53.4781 16.6867 52.2575L31.7504 37.1524C32.5112 36.4404 33.7285 36.5421 34.3371 37.4067Z",
                fill: "rgba(255,204,4,1)",
                fillRule: "nonzero",
              },
            ]}
            position="relative"
            {...getOverrideProps(overrides, "Vector39712008")}
          ></Icon>
          <Icon
            width="31.28px"
            height="35.19px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 31.28515625,
              height: 35.187744140625,
            }}
            paths={[
              {
                d: "M14.6483 26.3892C16.0684 26.2366 17.4886 26.7961 18.503 27.8132L25.8573 35.1878L27.9875 33.0517L26.6181 32.1362C25.6037 31.4242 24.9443 30.2545 24.8936 28.983C24.8429 27.7115 25.4515 26.5418 26.4152 25.7789L30.3713 22.7274C30.6757 22.4731 30.98 22.2188 31.2843 21.9645L27.7846 18.4552C26.7703 17.438 26.2123 16.014 26.3645 14.5899C26.7702 10.7755 25.5023 6.80853 22.5605 3.85871C19.7202 1.01061 15.9163 -0.260857 12.2138 0.0442964L13.6846 2.58724L17.0321 8.28343L13.9382 13.0642L8.25764 13.471L4.91015 7.77484L3.13497 4.72331C-1.27763 9.91092 -1.02403 17.6923 3.84504 22.6256C6.88821 25.5246 10.8443 26.7961 14.6483 26.3892Z",
                fill: "rgba(255,204,4,1)",
                fillRule: "nonzero",
              },
            ]}
            position="relative"
            {...getOverrideProps(overrides, "Vector39712009")}
          ></Icon>
          <Icon
            width="35.29px"
            height="31.27px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 35.291015625,
              height: 31.2716064453125,
            }}
            paths={[
              {
                d: "M20.5921 4.93331C19.172 5.08589 17.7518 4.52644 16.7374 3.50926L13.2378 0C12.9335 0.406871 12.5784 0.813739 12.2741 1.22061L9.73813 4.88245C9.02806 5.95048 7.8108 6.56079 6.54281 6.56079C5.27483 6.56079 4.05755 5.95048 3.34747 4.88245L2.13021 3.15325L0 5.28932L7.50648 12.8164C8.52087 13.8336 9.07878 15.2577 8.92662 16.6817C8.52086 20.4961 9.78885 24.4631 12.7306 27.4129C15.5709 30.261 19.3748 31.5325 23.0774 31.2273L21.6065 28.6844L18.259 22.9882L21.3529 18.2075L27.0335 17.8006L30.381 23.4968L32.1562 26.5483C36.5688 21.3607 36.3152 13.5793 31.4461 8.64601C28.3522 5.79791 24.3961 4.52644 20.5921 4.93331Z",
                fill: "rgba(255,204,4,1)",
                fillRule: "nonzero",
              },
            ]}
            position="relative"
            {...getOverrideProps(overrides, "Vector39712010")}
          ></Icon>
        </Icon>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="374px"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Title3909116")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="24px"
          fontWeight="700"
          color="rgba(25,27,31,1)"
          lineHeight="32px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          width="374px"
          grow="1"
          basis="374px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Custom Training and Content Development"
          {...getOverrideProps(
            overrides,
            "Custom Training and Content Development"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="374px"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Title3909118")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="18px"
          fontWeight="400"
          color="rgba(83,83,83,1)"
          lineHeight="32px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          width="374px"
          grow="1"
          basis="374px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="We can work with your organization to develop custom training based on your technology providers."
          {...getOverrideProps(
            overrides,
            "We can work with your organization to develop custom training based on your technology providers."
          )}
        ></Text>
      </Flex>
      <Button
        display="flex"
        gap="0"
        direction="row"
        width="fit-content"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="5px"
        padding="8px 16px 8px 16px"
        size="large"
        isDisabled={false}
        variation="default"
        children="Schedule a Meeting"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
