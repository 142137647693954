import styles from "./styles.module.scss";
import SimpleCard from "../card/SimpleCard";
import {ReactComponent as OwlLogo} from "../../assets/solutions/Train GRC Solutions Owl.svg";

const SecondBlock = () => {
  return (
    <div className={styles.secondBlock}>
      <OwlLogo className={styles.blueLogo}/>
      <SimpleCard
        titleFirst="Solutions You Need"
        titleSecond="Support You Deserve"
        description={[
          "Regardless of your skill level, Train GRC is here to accelerate your knowledge and support your cyber risk needs.",
        ]}
      />
    </div>
  );
};

export default SecondBlock;
