import styles from "./styles.module.scss";
import CISA from "../../assets/aboutUs/CISA.png";
import CISSP from "../../assets/aboutUs/CISSP.png";
import AWSCertified from "../../assets/aboutUs/awsCertified.png";
import MicrosoftSecurity from "../../assets/aboutUs/Microsoft.png";
import SecurityAdministrator from "../../assets/aboutUs/SecurityAdministrator.png";

const Certifications = () => {
  return (
    <div className={styles.certifications}>
      <div className={styles.inner}>
        <div className={styles.innerMost}>
          <div className={styles.title}>Certifications</div>
          <div className={styles.imageBlock}>
            <img className={styles.CISA} src={CISA} alt="CISA" />
            <img className={styles.CISSP} src={CISSP} alt="CISSP" />
            <img
              className={styles.awsCertified}
              src={AWSCertified}
              alt="AWSCertifed"
            />
            <img
              className={styles.securityAdministrator}
              src={SecurityAdministrator}
              alt="SecurityAdministrator"
            />

            <img
              className={styles.microsoftSecurity}
              src={MicrosoftSecurity}
              alt="MicrosoftSecurity"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
