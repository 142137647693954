import styles from "./coachCard.module.scss";
import { Button as AmplifyButton } from "@aws-amplify/ui-react";

const CoachCard = ({ title, description, imgSrc, onbtnClick, btnText }) => {
  return (
    <div className={styles.coachCard}>
      <div className={styles.content}>
        <div className={styles.image}>
          <img alt="imageSource" src={imgSrc} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.btn}>
        <AmplifyButton
          display="flex"
          gap="0"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="5px"
          border="1px solid #F2EFEE"
          padding="8px 16px 8px 16px"
          size="large"
          isDisabled={false}
          variation="primary"
          children={btnText}
          outline="none"
          backgroundColor="#F8CB2E"
          color="#191B1F"
          alignSelf="center"
          onClick={onbtnClick}
        />
      </div>
    </div>
  );
};

export default CoachCard;
