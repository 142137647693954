import styles from "./styles.module.scss";
import ExpertiseCard from "../card/ExpertiseCard";
import FirstImg from "../../assets/solutions/expertise/first.png";
import SecondImg from "../../assets/solutions/expertise/second.png";
import ThirdImg from "../../assets/solutions/expertise/third.png";
import FourthImg from "../../assets/solutions/expertise/four.png";
import FifthImg from "../../assets/solutions/expertise/five.png";
import SixthImg from "../../assets/solutions/expertise/six.png";

const Expertise = () => {
  const expertiseCards = [
    {
      title: (
        <p>
          Hands-On Labs
          <br /> For Each Cloud Environment
        </p>
      ),
      imgSrc: FirstImg,
      description:
        "We have built hands-on labs for AWS, Azure, and GCP to provide our students with real-world simulations of organizations and the security controls they may implement to address cybersecurity risks. ",
    },
    {
      title: (
        <p>
          Registered CPEs to
          <br /> Ensure Compliance
        </p>
      ),
      imgStyle: {
        width: "84px",
        height: "75px",
      },
      imgSrc: SecondImg,
      description:
        "Our trainings are NASBA registered. This means that you can use Continuing Professional Education  (CPE) credits from Train GRC to fulfill AICPA and other certification body requirements.",
    },
    {
      title: (
        <p>
          Identify and Explain
          <br /> Real World Risks
        </p>
      ),
      imgStyle: {
        width: "71px",
        height: "71px",
      },
      imgSrc: ThirdImg,
      description:
        "We don’t just walk you through the configurations that are necessary to protect your organization, we talk about some of the common threats that enhance risk and the controls that may mitigate or compensate for them.",
    },
    {
      title: (
        <p>
          State of the Art
          <br /> Content Production
        </p>
      ),
      imgStyle: {
        width: "75px",
        height: "75px",
      },
      imgSrc: FourthImg,
      description:
        "Train GRC has invested in cutting-edge capabilities for content production. These capabilities can be put to task for your custom training needs. We offer a full suite of custom training development services.",
    },
    {
      title: (
        <p>
          Advisory Services Trusted By
          <br /> Top Companies
        </p>
      ),
      imgStyle: {
        width: "63px",
        height: "75px",
      },
      imgSrc: FifthImg,
      description:
        "Our consultants have assisted Fortune 500 clients in highly regulated industries, from Energy companies to Financial institutions.",
    },
    {
      title: (
        <p>
          Tools For
          <br /> Audit Assistance
        </p>
      ),
      imgStyle: {
        width: "75px",
        height: "75px",
      },
      imgSrc: SixthImg,
      description:
        "Train GRC helps you learn the relevant techniques and gives you instant access to tools that can supercharge your cloud security audits.",
    },
  ];
  return (
    <div className={styles.expertise}>
      <h1 className={styles.title}>
        Expertise <span className={styles.textBlue}>you can rely on.</span>
      </h1>
      <div className={styles.cards}>
        {expertiseCards?.map((item, index) => (
          <ExpertiseCard {...item} key={index} />
        ))}
      </div>
    </div>
  );
};
export default Expertise;
