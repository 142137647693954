import React, { useState } from "react";
import {
  Collapse,
  NavbarToggler,
  // NavbarBrand,
  // Nav,
  // NavItem,
  // NavLink,
} from "reactstrap";
import {
  Button,
  Container,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import "./NavBar.css";
import classNames from "classnames";
import styles from "./navbar.module.scss";
import { Button as AmplifyButton } from "@aws-amplify/ui-react";
import { useMedia } from "react-use";
import Logo from "../assets/Logo.png";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const toggle = () => setIsOpen(!isOpen);
  const isMobile = useMedia("(max-width: 768px)", false);

  // if(isMobile){
  //   setIsOpen(isMobile)
  //   console.log(isMobile, 'isMobile')
  // }

  const ButtonBlock = () => {
    return (
      <div className={styles.btnBlock}>
        <AmplifyButton
          display="flex"
          gap="0"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="5px"
          border="1px solid #F2EFEE"
          padding="8px 16px 8px 16px"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Sign Up"
          outline="none"
          backgroundColor="#F2EFEE"
          color="#191B1F"
          fontWeight="700"
          alignSelf="center"
          onClick={() => {
            history.push("/signup");
          }}
        />
        <Button
          variant="light"
          className={styles.blueBtn}
          onClick={() => {
            window.location.href =
              "https://sso.teachable.com/secure/1110292/identity/login/password";
          }}
        >
          Login
        </Button>
      </div>
    );
  };

  const NavBarLinks = () => {
    return (
      <>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" isOpen={isOpen} navbar>
          <Nav className={styles.navLinks}>
            <NavItem>
              <NavLink
                className={styles.navLinkText}
                href="https://academy.traingrc.com/"
              >
                Academy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "solutions",
                  },
                ])}
                href="/solutions"
              >
                Solutions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "blog",
                  },
                ])}
                href="http://blog.traingrc.com/en"
              >
                Blog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "AboutUs",
                  },
                ])}
                href="/about-us"
              >
                About Us
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </>
    );
  };

  const LogoContainer = () => {
    return (
      <div className={styles.logoContainer}>
        <a href="/">
          <img
            className="logo"
            width="120"
            // src="https://traingrc.com/wp-content/uploads/2022/01/Primary-1.png"
            src={Logo}
            alt=""
            loading="lazy"
            // srcset="https://traingrc.com/wp-content/uploads/2022/01/Primary-1.png"
          ></img>
        </a>
      </div>
    );
  };

  return (
    <>
      {/* <Navbar className={styles.navbar} expand="lg">
      
        {!isMobile ? (
          <>
          
            <NavBarLinks />
            <LogoContainer />
            <ButtonBlock />
          </>
        ) : (
          <div className={styles.mobileInner}>
            <div className={styles.firstRow}>
              
              <LogoContainer />
            </div>
            <div className={styles.secondRow}>
              <NavBarLinks1 />   
            </div>
          </div>
        )}
      </Navbar> */}
      <header>
        <div className={styles.navbar}>
          <div className={styles.links}>
            <NavItem>
              <NavLink
                className={styles.navLinkText}
                style={{ color: "#191B1F" }}
                href="https://academy.traingrc.com/"
              >
                Academy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "solutions",
                  },
                ])}
                style={{ color: "#191B1F" }}
                href="/solutions"
              >
                Solutions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "blog",
                  },
                ])}
                style={{ color: "#191B1F" }}
                href="http://blog.traingrc.com/en"
              >
                Blog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames([
                  styles.navLinkText,
                  {
                    "font-weight-bold": props?.active === "AboutUs",
                  },
                ])}
                style={{ color: "#191B1F" }}
                href="/about-us"
              >
                About Us
              </NavLink>
            </NavItem>
            {/* <a className={styles.linkItem}>Solutions</a> */}

            {/* <a className={styles.linkItem}>Blog</a>
        <a className={styles.linkItem}>About Us</a> */}
          </div>
          <div className={styles.logo}>
            <img src={Logo} alt="Person" />
          </div>
          <ButtonBlock />
          <div className={styles.hamburger} onClick={() => setIsOpen(!isOpen)}>
            <svg
              width="22"
              height="14"
              viewBox="0 0 22 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="1" x2="22" y2="1" stroke="#191B1F" stroke-width="2" />
              <line y1="7" x2="22" y2="7" stroke="#191B1F" stroke-width="2" />
              <line y1="13" x2="22" y2="13" stroke="#191B1F" stroke-width="2" />
            </svg>
          </div>
        </div>
        {isOpen && (
          <div className={styles.responsive} id="responsive_navbar">
            <div className={styles.responsive_navbar}>
              <div className={styles.responsive_logo}>
                <img
                  width="120"
                  // src="https://traingrc.com/wp-content/uploads/2022/01/Primary-1.png"
                  src={Logo}
                  alt="NavLogo"
                  loading="lazy"
                />
              </div>
              <div className={styles.links}>
                <NavItem>
                  <NavLink
                    className={styles.navLinkText}
                    style={{ color: "#191B1F" }}
                    href="https://academy.traingrc.com/"
                  >
                    Academy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames([
                      styles.navLinkText,
                      {
                        "font-weight-bold": props?.active === "solutions",
                      },
                    ])}
                    style={{ color: "#191B1F" }}
                    href="/solutions"
                  >
                    Solutions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames([
                      styles.navLinkText,
                      {
                        "font-weight-bold": props?.active === "blog",
                      },
                    ])}
                    style={{ color: "#191B1F" }}
                    href="/blog"
                  >
                    Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames([
                      styles.navLinkText,
                      {
                        "font-weight-bold": props?.active === "AboutUs",
                      },
                    ])}
                    style={{ color: "#191B1F" }}
                    href="/about-us"
                  >
                    About Us
                  </NavLink>
                </NavItem>
              </div>
              <div className={styles.hr} />
              <ButtonBlock />
            </div>
            <div
              className={styles.backdrop}
              onClick={() => setIsOpen(!isOpen)}
            ></div>
          </div>
        )}
      </header>
    </>
  );
  // return (
  //   <Navbar className{styles.navbar}" default expand="md">
  //     <Container fluid>
  //       {/* <NavbarBrand href="/">Train GRC</NavbarBrand> */}
  //       {/* <NavbarToggler onClick={toggle} /> */}
  //       {/* <Collapse isOpen={isOpen} navbar> */}
  //       <div></div>
  //       {/* </Collapse> */}
  //     </Container>
  //   </Navbar>
  // );
};

export default NavBar;
