import styles from "./styles.module.scss";
import { ReactComponent as ArrowDoted } from "../../assets/svg/arrowDoted.svg";
import AuthLogo1 from "../../assets/authLogo1.png";
import CurveRectangular from "../../assets/curveRectangular.png";
import { useMedia } from "react-use";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import Vector from "../../assets/Vector.png"

const Footer = ({ color }) => {
  const isMobile = useMedia("(max-width: 768px)", false);
  const arrowIcon = useMedia("(max-width:958px)", false)
  const history = useHistory();

  return (
    <div
      className={styles.footer}
      style={{
        backgroundColor: color ? color : "white",
        borderTop: color ? `1px solid ${color}` : "1px solid white",
      }}
    >
      <div className={styles.firstItem}>
        {!isMobile && (
          <div className={styles.background}>
            <img
              src={CurveRectangular}
              alt="curveRectangular"
              className={styles.curveRectangular}
            />
          </div>
        )}
        <div className={styles.innerFirst}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Join Train GRC Academy + Become A Cyber Risk Expert.
            </h2>
            <ul className={styles.list}>
              <li className={styles.item}>
                Stay Ahead Of The Latest Tech Trends
              </li>
              <li className={styles.item}>Breeze Through Assessments</li>
              <li className={styles.item}>
                Stop Hearing You Aren’t “Technical Enough”
              </li>
            </ul>
          </div>
          {!isMobile ? (
            <>
              <div className={styles.arrowIcon}>
                <ArrowDoted style={{ marginLeft: "-8rem", height: `${arrowIcon ? "70px" : "90px"}` }} />
              </div>


              <Button className={styles.joinBtn} variant="warning" onClick={() => history.push("/signup")}>
                Join Now
              </Button>

              <div className={styles.authLogo}>
                <img src={AuthLogo1} alt="authLogo" />
              </div>
            </>
          ) : (

            <>
              <Button className={styles.joinBtn} variant="warning" onClick={() => history.push("/signup")}>
                Join Now
              </Button>
              <div className={styles.bottomImg}>
              <img
              className={styles.vector}
               src={Vector}
                alt="vector"/>
              <div className={styles.authLogo}>
                <img src={AuthLogo1} alt="authLogo" />
              </div>
              </div>
            </>

          )
          }
        </div>
      </div>
      <div className={styles.secondItem}>
        <img
          className="logo"
          width="120"
          src={Logo}
          // src="https://traingrc.com/wp-content/uploads/2022/01/Primary-1.png"
          alt=""
          loading="lazy"
        // srcset="https://traingrc.com/wp-content/uploads/2022/01/Primary-1.png"
        />
        <div className={styles.content}>
          <div className={styles.item}>
            <div>
              <b>Services</b>
            </div>
            <div>Courses</div>
            <div>Solutions</div>
          </div>

          <div className={styles.item}>
            <div>
              <b>Train GRC Labs</b>
            </div>
            <div>Blog</div>
            <div>About Us</div>
          </div>

          <div className={styles.item}>
            <div>
              <b>Contact Us</b>
            </div>
            <div>Contact Us</div>
            <div>Schedule a meeting</div>
          </div>
          <div className={styles.item}/>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.right}>© All Rights Reserved</div>
      </div>
    </div>
  );
};
export default Footer;
