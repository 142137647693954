import React from "react";
// import NavBar from "../components/NavBar";
import { SignUp as SignupComponent } from "../components/Auth";
import { useTitle } from "../hooks";

const Signup = () => {
  useTitle("Sign Up For Train GRC Academy");
  return (
    <div>
      <SignupComponent />
    </div>
  );
};

export default Signup;
