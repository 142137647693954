import styles from "./styles.module.scss";
import Layout from "../../components/Home/layout";
import SecondBlock from "../../components/solutions/secondBlock";
import CardBlock from "../../components/solutions/cardBlock";
import Expertise from "../../components/solutions/expertise";
import { useTitle } from "../../hooks";

const Solutions = () => {
  useTitle("Train GRC - Find Your Solution");
  return (
    <Layout active="solutions" color="#F2EFEE">
      <div className={styles.solutions}>
        <SecondBlock />
        <CardBlock />
        <Expertise />
      </div>
    </Layout>
  );
};

export default Solutions;
