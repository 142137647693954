import Layout from "../../components/Home/layout";
import FirstBlock from "../../components/AboutUs/FirstBlock";
import Certifications from "../../components/AboutUs/certifications";
import CardBlock from "../../components/AboutUs/cardBlock";
import styles from "./styles.module.scss";
import { useTitle } from "../../hooks";

const AboutUs = () => {
  useTitle("Train GRC Leadership");
  return (
    <Layout active="AboutUs" color="#F2EFEE">
      <div className={styles.aboutUs}>
        <div className={styles.first}>
          <FirstBlock />
          <Certifications />
        </div>
        <CardBlock />
      </div>
    </Layout>
  );
};
export default AboutUs;
