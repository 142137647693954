import { useState } from "react";
import styles from "./styles.module.scss";
import Help_1 from "../../assets/home/help_1.png";
import Help_2 from "../../assets/home/help_2.png";
import Help_3 from "../../assets/home/help_3.png";
import CoachCard from "../card/CoachCard";
import { useHistory } from "react-router-dom";
import CalendlyPopupModal from "../modals/CalendlyPopupModal";

const CardBlock = () => {
  const history = useHistory();
  const [openScheduleMeetingFirst, setOpenScheduleMeetingFirst] =
    useState(false);
  const [openScheduleMeetingSecond, setOpenScheduleMeetingSecond] =
    useState(false);

  return (
    <div className={styles.cardBlock}>
      <CoachCard
        imgSrc={Help_1}
        title="Train GRC Academy"
        description="Access expert tutorials on important cloud auditing topics and develop skills through interactive labs at the Train GRC Academy."
        btnText="Sign Up Now"
        onbtnClick={() => history.push("/signup")}
      />
      <CoachCard
        imgSrc={Help_2}
        title="Train GRC Studios"
        description="We can work with your organization to develop custom training based on your technology providers."
        btnText="Schedule a Meeting"
        onbtnClick={() => setOpenScheduleMeetingFirst(true)}
      />
      <CoachCard
        imgSrc={Help_3}
        title="Advisory Services"
        description="Our consultants can help your organization with audit support services, including cohort-based coaching"
        btnText="Schedule a Meeting"
        onbtnClick={() => setOpenScheduleMeetingSecond(true)}
      />
      <CalendlyPopupModal
        url="https://calendly.com/traingrc/custom-training-services"
        onClose={() => setOpenScheduleMeetingFirst(false)}
        open={openScheduleMeetingFirst}
      />
      <CalendlyPopupModal
        url="https://calendly.com/traingrc/initial-advisory-requests"
        onClose={() => setOpenScheduleMeetingSecond(false)}
        open={openScheduleMeetingSecond}
      />
    </div>
  );
};

export default CardBlock;
