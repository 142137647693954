import styles from "./simpleCard.module.scss";

const SimpleCard = ({
  titleFirst,
  titleSecond,
  description,
  cardStyle = {},
}) => {
  return (
    <div className={styles.card} style={{ ...cardStyle }}>
      <h2 className={styles.title}>
        {titleFirst}
        <br />
        <span className="text-warning">{titleSecond}</span>
      </h2>
      <div className={styles.description}>
        {description?.map((item) => {
          return <div>{item}</div>;
        })}
      </div>
    </div>
  );
};

export default SimpleCard;
