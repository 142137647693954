import { useRef, useState, useEffect } from "react";
import { Form, FormGroup, Row, Col, InputGroup, Button } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { TimeZoneList } from "./timeZone";
export const UserForm = ({
  errors,
  firstName,
  lastName,
  email,
  timeZone,
  setFirstName,
  setLastName,
  setEmail,
  setTimeZone,
  linkedInProfile,
  setLinkedInProfile,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);

  useEffect(() => {
    if (passVisible) {
      passwordRef.current?.setAttribute("type", "text");
    } else {
      passwordRef.current?.setAttribute("type", "password");
    }
  }, [passVisible]);

  useEffect(() => {
    if (confirmPassVisible) {
      passwordConfirmRef.current?.setAttribute("type", "text");
    } else {
      passwordConfirmRef.current?.setAttribute("type", "password");
    }
  }, [confirmPassVisible]);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className={styles.userForm2}>
      <Form>
        <FormGroup>
          <Form.Label>Email Address</Form.Label>
          <span className={styles.required}>*</span>

          <Form.Control
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="name@address.com"
            className={classNames({
              [styles.errorInput]: errors?.email,
            })}
          />

          {errors?.email && (
            <Form.Text className={styles.textRed}>{errors?.email}</Form.Text>
          )}
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Form.Label>First Name</Form.Label>
              <span className={styles.required}>*</span>
              <Form.Control
                value={firstName}
                onChange={(e) =>
                  setFirstName(capitalizeFirstLetter(e.target.value))
                }
                type="firstName"
                placeholder="First Name"
                className={classNames({
                  [styles.errorInput]: errors?.firstName,
                })}
              />

              {errors?.firstName && (
                <Form.Text className={styles.textRed}>
                  {errors?.firstName}
                </Form.Text>
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Form.Label>Last Name</Form.Label>
              <span className={styles.required}>*</span>
              <Form.Control
                value={lastName}
                onChange={(e) =>
                  setLastName(capitalizeFirstLetter(e.target.value))
                }
                type="text"
                placeholder="Last Name"
                className={classNames({
                  [styles.errorInput]: errors?.lastName,
                })}
              />
              {errors?.lastName && (
                <Form.Text className={styles.textRed}>
                  {errors?.lastName}
                </Form.Text>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <span className={styles.required}>*</span>
          <InputGroup>
            <Form.Control
              placeholder="Enter Your Password"
              type="password"
              ref={passwordRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classNames({
                [styles.errorInput]: errors?.password,
              })}
            />
            <InputGroup.Append>
              <Button
                variant="light"
                onClick={() => {
                  setPassVisible((s) => !s);
                }}
                className={classNames(
                  {
                    [styles.errorInput]: errors?.password,
                  },
                  {
                    pass: !errors?.password,
                  }
                )}
              >
                {passVisible ? <EyeSlash /> : <Eye />}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {errors?.password && (
            <Form.Text
              className={classNames([styles.textRed, styles.passwordErr])}
            >
              {errors?.password}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <span className={styles.required}>*</span>
          <InputGroup>
            <Form.Control
              placeholder="Confirm Your Password"
              type="password"
              ref={passwordConfirmRef}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={classNames({
                [styles.errorInput]: errors?.confirmPassword,
              })}
            />

            <InputGroup.Append>
              <Button
                variant="light"
                onClick={() => {
                  setConfirmPassVisible((s) => !s);
                }}
                className={classNames(
                  {
                    [styles.errorInput]: errors?.confirmPassword,
                  },
                  {
                    pass: !errors?.confirmPassword,
                  }
                )}
              >
                {confirmPassVisible ? <EyeSlash /> : <Eye />}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {errors?.confirmPassword && (
            <Form.Text className={styles.textRed}>
              {errors?.confirmPassword}
            </Form.Text>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};
