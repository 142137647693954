import { useState } from "react";
import { useMedia } from "react-use";
import { Frame552 } from "../../ui-components";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import CalendlyPopupModal from "../modals/CalendlyPopupModal";

const HelpBlock = () => {
  const history = useHistory();
  const [openScheduleMeetingFirst, setOpenScheduleMeetingFirst] =
    useState(false);
  const [openScheduleMeetingSecond, setOpenScheduleMeetingSecond] =
    useState(false);

  return (
    <div className={styles.helpBlock}>
      <h1 className={styles.title}>How Train GRC Can Help</h1>
      <div className={styles.content}>
        <Frame552
          display="flex"
          flexDirection="column"
          className={styles.helpList}
          overrides={{
            "Frame 545": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "Get Access to Individual Courses For Each Cloud Provider Featuring Hands-On Labs at Train GRC Academy.":
              {
                children:
                  "Get access to individual courses for each cloud provider featuring hands-on labs at Train GRC Academy.",
              },
            "Train GRC Academy": {
              width: "100%",
              whiteSpace: "normal",
            },
            Title3909112: {
              className: "description",
            },
            Title3909114: {
              className: "description",
            },
            "Frame 550": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              className: "specificCard",
            },
            "Frame 551": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              className: "specificCard",
            },
            "Advisory Services and One-on-One Coaching": {
              children: "Advisory Services",
            },
            Button50110155: {
              backgroundColor: "rgba(255,204,4,1)",
              border: "none",
              fontFamily: "Poppins",
              onClick: () => setOpenScheduleMeetingSecond(true),
            },
            Button50110193: {
              backgroundColor: "rgba(255,204,4,1)",
              border: "none",
              fontFamily: "Poppins",
              children: "Sign Up Now",
              onClick: () => history.push("/signup"),
            },
            "Frame 549": {
              className: "specificCard",
              overrides: {
                "Custom Training and Content Development": {
                  children: "Train GRC Studios",
                },
                Button: {
                  backgroundColor: "rgba(255,204,4,1)",
                  border: "none",
                  fontFamily: `"Poppins", sans-serif`,
                  onClick: () => {
                    setOpenScheduleMeetingFirst(true);
                  },
                },
                Title3909118: {
                  className: "description",
                },
                Title3909116: {
                  className: "description",
                },
              },
            },
          }}
        />
        <CalendlyPopupModal
          url="https://calendly.com/traingrc/custom-training-services"
          onClose={() => setOpenScheduleMeetingFirst(false)}
          open={openScheduleMeetingFirst}
        />
        <CalendlyPopupModal
          url="https://calendly.com/traingrc/initial-advisory-requests"
          onClose={() => setOpenScheduleMeetingSecond(false)}
          open={openScheduleMeetingSecond}
        />
        {/* <Frame549
          display="flex"
          justifyContent="center"
          alignItems="center"
          overrides={{
            Button: {
              backgroundColor: "rgba(255,204,4,1)",
              border: "none",
              fontfamily: `"Poppins", sans-serif`,
            },
          }}
        /> */}
        {/* <HelpItem
          imgSrc={Help_1}
          title="Train GRC Academy"
          description="Get Access to Individual Courses For Each Cloud Provider Featuring 
 Hands-On Labs at Train GRC Academy."
          footerIcon={<ArrowRightCircle />}
          footerText="Sign Up"
        />
        <HelpItem
          imgSrc={Help_2}
          title="Custom Training and Content Development"
          description="We can work with your organization to develop custom training based on your technology providers."
          footerIcon={<Calendar4 />}
          footerText="Schedule a Meeting"
        />
        <HelpItem
          imgSrc={Help_3}
          title="Advisory Services and One-on-One Coaching"
          description="Our consultants can help your organization with audit support services, including cohort-based coaching"
          footerIcon={<Calendar4 />}
          footerText="Schedule a Meeting"
        /> */}
      </div>
    </div>
  );
};
export default HelpBlock;
